html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
}

.MuiFormControl-root {
    width: 100%;
    box-sizing: border-box;
}
